.ism-nav__tab {
  background-color: #00ff87;
  color: #37003c;

  padding-right: 3rem;
  padding-left: 3rem;

  padding: 0 1.8rem;
  transition: all 0.2s;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  line-height: 4rem;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.ism-nav__tab.active {
  background-color: white;
}

.ism-nav__tab.isDraft {
  background-color: #963cff;
  color: white;
}
