.dropdown {
  display: inline-block;
  position: relative;
}

.list {
  position: absolute;
  visibility: hidden;
  transform: scale(0);
  top: 100%;
  right: 0;
  width: 18rem;
  transition: 0.1s;
  border-radius: 0 0 3px 3px;
  background-color: white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  z-index: 100;
  -webkit-transform-origin: top right;
  transform-origin: top right;

  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.list.show {
  visibility: visible;
  transform: scale(1);
}

.list-item {
  padding: 0 1.8rem;
  transition: all 0.2s;
  border-radius: 2px 2px 0 0;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 4rem;
  text-align: center;
  text-decoration: none;
  display: block;
  color: #242424;
}

.list-item:hover {
  color: #e90052;
}

.button {
  position: relative;
  padding: 0 1.8rem;
  transition: all 0.2s;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border: 0;
  background-color: #00ff87;
  color: #37003c;
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
